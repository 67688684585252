import { Splide, SplideSlide } from "@splidejs/react-splide";

const MwaSlider = () => {
  return (
    <div className="container mx-auto py-10">
      <Splide options={{ autoplay: true, type: "loop", focus: "center" }}>
        {["./img/slider-01.jpg", "./img/slider-02.jpg"].map((item, index) => (
          <SplideSlide key={index} className="flex justify-center">
            <img src={item} alt={`Image ${index}`} />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default MwaSlider;
