const MwaServices = () => {
  return (
    <div data-section id="services" className="lg:container mx-auto">
      <div className="flex flex-col py-10">
        <span className="text-primary text-center text-3xl font-bold uppercase mb-10">
          DỊCH VỤ CỦA CHÚNG TÔI
        </span>

        <div className="grid xl:grid-cols-4 lg:grid-cols-2 grid-col-1 gap-8">
          {[
            { imgSrc: "./img/icon-01.png", name: "Taxi giá rẻ" },
            { imgSrc: "./img/icon-02.png", name: "Taxi đường dài" },
            { imgSrc: "./img/icon-03.png", name: "Taxi hợp đồng" },
            { imgSrc: "./img/icon-04.png", name: "Đưa đón sân bay" },
          ].map((item, index) => (
            <div
              key={index}
              className="shadow-md rounded-lg flex flex-col items-center p-4"
            >
              <img src={item.imgSrc} alt={item.name} className="h-16" />
              <span className="uppercase text-xl mt-4">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MwaServices;
