import axios from "axios";
import { useSnackbar } from "notistack";
import { FormEventHandler, useState } from "react";
import "./mwa_create_order_from.scss";

const MwaCreateOrderForm = () => {
  const [fullName, setFullName] = useState("");
  const [description, setDescription] = useState("");

  const [isCreatingState, setCreatingState] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  function _extractPhoneNumbers(inputString: string): string[] {
    // const phoneNumberRegex = /(?:\+84|0)(\d{9,10})/g; OLD REGEX
    const phoneNumberRegex =
      /(?:\+84|0)[\s.]*([0-9]{2,4})[\s.]*([0-9]{2,4})[\s.]*([0-9]{3,4})/g;

    const matches = inputString.match(phoneNumberRegex);
    return matches || [];
  }

  const _handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();

    const phoneNumbers = _extractPhoneNumbers(description);

    if (phoneNumbers.length === 0) {
      enqueueSnackbar(
        "Vui lòng nhập số điện thoại liên hệ vào Nội dung tìm xe. Ví dụ: 0987654321 hoặc +84987654321",
        {
          autoHideDuration: 4000,
          variant: "error",
        }
      );
      return;
    }
    setCreatingState(true);

    await _createOrder({ senderPhoneNumber: phoneNumbers[0] });

    setCreatingState(false);
  };

  async function _createOrder({
    senderPhoneNumber,
  }: {
    senderPhoneNumber: String;
  }) {
    const requestData = {
      senderName: fullName.trim(),
      senderPhoneNumber: senderPhoneNumber.replace(/[.\s]+/g, "").trim(),
      description: description.trim(),
    };

    try {
      const response = await axios.post(
        "https://delivery-system-api.pycom.vn/api/stock-orders/by-raw-content-anonymous",
        requestData,
        {
          headers: {
            accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFullName("");
        setDescription("");
        enqueueSnackbar(
          "Tạo đơn thành công. Tài xế nhận đơn sẽ liên lạc với bạn trong thời gian sớm nhất. Xin cảm ơn!!!",
          {
            autoHideDuration: 6000,
            variant: "success",
          }
        );
        return;
      }

      enqueueSnackbar(
        `Gửi yêu cầu bị lỗi - HTTP Status Code ${response.status}`,
        {
          autoHideDuration: 4000,
          variant: "error",
        }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.toString() ?? "Tạo đơn bị lỗi", {
        autoHideDuration: 4000,
        variant: "error",
      });
    }
  }

  return (
    <div
      data-section
      id="create-order-form"
      className="shadow-lg rounded flex flex-col items-stretch lg:m-0 m-5"
    >
      <img src="./img/temp-img.png" />
      {/* <div className="lg:p-4 p-2 bg-primary text-center text-white lg:text-xl text-lg font-bold">
        Đặt ngay
      </div>
      <div className="w-full p-5">
        <div className="mb-5">
          <label className="block text-gray-700 mb-2">Họ và tên</label>
          <input
            readOnly={isCreatingState}
            className="text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="fullName"
            type="text"
            value={fullName}
            placeholder="Nhập họ và tên (Không bắt buộc)"
            onChange={(event) => setFullName(event.target.value)}
          />
        </div>
        <div className="mb-5">
          <div className="flex items-center mb-2">
            <label className="block text-gray-700 flex-1">
              Nội dung tìm xe (Cần có số điện thoại)
            </label>
            {description ? (
              <button
                className="ml-1 text-red-600"
                onClick={() => {
                  setDescription("");
                }}
              >
                <FaSquareXmark className="mr-1" />
              </button>
            ) : (
              <button
                className="ml-1 flex items-center text-green-600"
                onClick={() => {
                  navigator.clipboard
                    .readText()
                    .then((text) => {
                      setDescription(text);
                    })
                    .catch((err) => {
                      enqueueSnackbar("Không có dữ liệu để dán", {
                        autoHideDuration: 4000,
                        variant: "error",
                      });
                    });
                }}
              >
                <FaRegPaste className="mr-1" /> Dán
              </button>
            )}
          </div>
          <textarea
            readOnly={isCreatingState}
            className="text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows={15}
            value={description}
            placeholder="Ví dụ: Xe 8 tấn 55 khối Hà Nội - Đà Nẵng, Sài Gòn - Đà Nẵng. LH: 0987654321"
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
        <button
          disabled={description.length === 0}
          className={`p-2 rounded w-full flex items-center justify-center bg-primary text-white disabled:bg-slate-400 disabled:cursor-not-allowed
          ${isCreatingState ? "cursor-default" : "hover:bg-primaryDark"}`}
          onClick={!isCreatingState ? _handleSubmit : undefined}
        >
          {isCreatingState ? (
            <ImSpinner2 className="loading-icon text-2xl" />
          ) : (
            <>
              <IoIosSend className="mr-2" /> Tìm xe
            </>
          )}
        </button>
      </div> */}
    </div>
  );
};

export default MwaCreateOrderForm;
