import { SnackbarProvider } from "notistack";
import {
  MwaFooter,
  MwaTeam,
  MwaIntroduction,
  MwaNavbar,
  MwaPricePlan,
  MwaServices,
  MwaSlider,
} from "../components/export";
import "./App.scss";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MwaNavbar />
      <MwaIntroduction />
      <MwaServices />
      <MwaPricePlan />
      <MwaTeam />
      <MwaSlider />
      <MwaFooter />
    </SnackbarProvider>
  );
}

export default App;
