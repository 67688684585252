import { useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { TiThMenu } from "react-icons/ti";
import "./mwa_navbar.scss";

export const navbarItems = [
  { name: "Giới thiệu", href: "#introduction" },
  { name: "Dịch vụ", href: "#services" },
  { name: "Bảng giá", href: "#price-plan" },
];

const MwaNavbar = () => {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <div className="shadow-md w-full fixed top-0 left-0 flex flex-col">
      <div
        className="py-2 lg:px-10 px-5"
        style={{ backgroundImage: 'url("./img/nav-bar-bg.png")' }}
      >
        <div className="w-fit">
          <a href="tel:+84982444737">
            <img src="./img/hotline.png" className="h-12" alt="hotline" />
          </a>
        </div>
      </div>
      <div
        id="nav-bar"
        className="lg:flex lg:flex-row lg:justify-between lg:items-center flex-col items-start justify-start py-2 lg:px-10"
      >
        <div className="flex">
          <button
            className="lg:hidden text-2xl px-4"
            onClick={() => setOpenNavbar(!openNavbar)}
          >
            {openNavbar ? <FaXmark /> : <TiThMenu />}
          </button>
          <div className="flex uppercase ml-2 font-bold">
            HTX TAXI CAO NGUYEN
          </div>
        </div>
        <ul
          className={`lg:bg-transparent bg-white lg:flex lg:items-center lg:static lg:w-auto lg:z-auto lg:pl-0 lg:pb-0 absolute z-[-1] left-0 w-full pl-9 pb-5 transition-all duration-500 ease-in 
        ${openNavbar ? "opacity-100" : "top-[-50vh]"} lg:opacity-100 opacity-0`}
        >
          {navbarItems.map((item) => (
            <li
              key={item.name}
              className="lg:ml-8 lg:my-0 my-7 text-base hover:text-secondary duration-500"
            >
              <a href={item.href} onClick={() => setOpenNavbar(false)}>
                {item.name.toUpperCase()}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MwaNavbar;
