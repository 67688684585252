import { FaAppStore } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";

const MwaTeam = () => {
  return (
    <div id="team" className="bg-secondary">
      <div className="lg:container mx-auto flex flex-col py-10">
        <span className="text-center text-white text-3xl font-bold uppercase mb-10">
          THÔNG TIN VỀ ĐỘI XE
        </span>

        <div className="grid xl:grid-cols-4 lg:grid-cols-2 grid-col-1 gap-8">
          {[
            { imgSrc: "./img/icon-01.png", name: "Hơn 100 xe 4 chổ" },
            { imgSrc: "./img/icon-05.png", name: "Hơn 100 xe 7 chổ" },
            { imgSrc: "./img/icon-06.png", name: "Hơn 1000 điểm đón " },
            { imgSrc: "./img/icon-07.png", name: "Đón 1000 khách hàng" },
          ].map((item, index) => (
            <div
              key={index}
              className="shadow-md rounded-lg bg-white flex flex-col items-center p-4"
            >
              <img src={item.imgSrc} alt={item.name} className="h-16" />
              <span className="text-xl mt-4">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MwaTeam;
