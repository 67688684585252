import { FaCheck } from "react-icons/fa6";

const MwaPricePlan = () => {
  return (
    <div data-section id="price-plan" className="py-10 lg:container mx-auto">
      <div className="grid lg:grid-cols-2 grid-col-1 gap-8">
        {[
          {
            title: "BẢNG GIÁ XE 4 CHỔ",
            content: [
              "Dưới 10km  - Giá 15k / 1km",
              "Dưới 100km  - Giá 13km / 1km",
              "Từ 100km trở lên - Giá 12k / 1km",
              "Đi khứ hồi lượt về giảm đến 70% (tùy quãng đường)",
              "Phí đường bộ khách thanh toán (nếu có)",
            ],
          },
          {
            title: "BẢNG GIÁ XE 7 CHỔ",
            content: [
              "Dưới 10km – Giá 18k / 1km",
              "Dưới 100km  - Giá 16km / 1km",
              "Từ 100km trở lên - Giá 13k / 1km ",
              "Đi khứ hồi lượt về giảm đến 70% (tùy quãng đường)",
              "Phí đường bộ khách thanh toán (nếu có)",
            ],
          },
        ].map((item, index) => (
          <div
            key={index}
            className="shadow-lg rounded-md flex flex-col items-stretch lg:m-0 m-5"
          >
            <div className="lg:p-4 p-2 uppercase bg-primary text-center text-white lg:text-xl text-lg font-bold">
              {item.title}
            </div>
            <div className="p-4">
              <div className="grid text-lg gap-4">
                {item.content.map((item2, index2) => (
                  <div key={index2} className="flex items-center">
                    <FaCheck className="text-green-600 me-2" /> {item2}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MwaPricePlan;
