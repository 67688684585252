import { navbarItems } from "../navbar/mwa_navbar";

const MwaFooter = () => {
  return (
    <footer className="text-sm text-white">
      <div className="bg-secondary p-8 flex flex-col items-center">
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 w-full">
          <div className="flex flex-col text-xl lg:order-1 order-2">
            <span>© Powered By PyCOM Solutions</span>
            <span>All rights reserved</span>
          </div>
          <div className="order-1">
            <span className="font-semibold text-lg">Liên hệ</span>
            <div className="border-b-2 border-white w-full my-2"></div>
            <div className="text-xs">
              <li className="py-1">
                Địa chỉ: 93 Hà Huy Tập, Tân Lợi, TP Buôn Ma Thuột, Đắk Lắk
              </li>
              {/* <li className="py-1">Mã số thuế: 0317800291</li>
              <li className="py-1">
                Email: <a href="mailto:info@pycom.vn">info@pycom.vn</a>
              </li> */}
              <li className="py-1">
                Hotline: <a href="tel:+84982444737">0982 444 737</a>
              </li>
            </div>
          </div>
          <div className="order-1">
            <span className="font-semibold text-lg">Về chúng tôi</span>
            <div className="border-b-2 border-white w-full my-2"></div>
            <div className="text-xs">
              {navbarItems.map((item) => (
                <li key={item.name} className="py-1">
                  <a href={item.href}>{item.name}</a>
                </li>
              ))}
            </div>
          </div>
          <div className="order-1">
            <span className="font-semibold text-lg">Cam kết</span>
            <div className="border-b-2 border-white w-full my-2"></div>
            <div className="text-xs">
              {[
                "Xe sạch, thoáng mát",
                "Lái xe an toàn, cẩn thận",
                "Không lái xe khi đã uống bia, rượu",
                "Nhiệt tình, chu đáo",
              ].map((item, index) => (
                <li key={index} className="py-1">
                  <span>{item}</span>
                </li>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-orange-800 text-center p-2">
        Bản Quyền 2023 © Công Nghệ PyCOM SOLUTIONS
      </div> */}
    </footer>
  );
};

export default MwaFooter;
