import { MwaCreateOrderForm } from "../export";

const MwaIntroduction = () => {
  return (
    <>
      <div
        data-section
        id="introduction"
        className="lg:bg-cover bg-contain bg-no-repeat bg-secondary w-full lg:h-[90vh] lg:mt-0 mt-28"
        style={{
          backgroundImage: "url('img/banner.jpg')",
        }}
      >
        <div className="lg:h-full grid lg:grid-cols-6 grid-cols-1 gap-4">
          <div className="lg:col-span-3">
            <div className="lg:h-full flex flex-col lg:justify-evenly justify-center px-10 p-4">
              <div className="flex flex-col">
                <span className="text-white lg:text-5xl text-xl font-semibold mb-2">
                  Nhanh chóng - An toàn
                </span>
                <span className="text-white lg:text-5xl text-xl font-semibold">
                  Uy tín - Chất lượng
                </span>
              </div>
              <div></div>
            </div>
          </div>
          <div className="lg:col-end-7 lg:col-span-3 self-center lg:pb-10 lg:pt-0 pt-12 lg:pr-10 lg:mt-0">
            <MwaCreateOrderForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default MwaIntroduction;
